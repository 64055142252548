.campaign-details {
  h1 {
    width: 56em;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .hidden-btn {
    width: 200px;
    padding: 12px 32px;
    border-radius: 100px;
    border: none;
    background-color: $black;
    font-size: 0px;
    font-weight: bold;
    color: $white;
    cursor: pointer;
    transition: all ease 0.3s;

    &::after {
      font-size: 16px;
      content: 'Joined';
    }

    &:not(:disabled):hover {
      background-color: $dark-purple;
      &::after {
        content: 'Leave campaign';
      }
    }
  }
}

.MuiDialog-paper {
  > * {
    background-color: $dark-blue;
    color: $white;
  }
}

.form {
  .form-field__container {
    width: 100%;
    text-align: left;
    margin-bottom: 8px;

    label {
      margin-left: 24px;
    }

    input:not([type='file']),
    select,
    textarea {
      width: 100%;
      font-size: 18px;
      font-style: italic;
      padding: 8px 24px;
      margin-top: 8px;
      border-radius: 24px;
      border: 2px solid $white;
      background: transparent;
      color: $white;

      &::placeholder {
        color: $dark-gray;
      }

      &:disabled {
        cursor: not-allowed;
        color: $gray;
      }
    }
  }
}

.show-more__wrapper {
  position: relative;

  .show-more__content {
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;

    &.show {
      max-height: 2000px;
    }
  }
}
