.campaign-card {
  background-color: $white;

  .campaign-card__img-container {
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;

    img {
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }

  .campaign-card__info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    text-align: left;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 24px;
    }

    .campaign-card__subtitle {
      color: $dark-gray;
      font-size: 0.75rem;
      font-weight: 600;
    }

    .campaign-card__text-container {
      max-width: 66%;
      @media (max-width: 768px) {
        max-width: 100%;
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
      }

      .campaign-card__title {
        color: $purple;
        font-size: 1.5rem;
        font-weight: bold;
        /* truncate string with ellipsis */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .campaign-card__btn-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .vote-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $black;
        padding: 16px 20px;
        border-radius: 50px;
        border: 2px solid $black;
        font-weight: 600;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        transition: all 0.3s;

        &:hover {
          background-color: $white;
          color: $black;
        }
      }
    }
  }
}

.img-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.variant-1 {
    background: linear-gradient(180deg, #930ad4 0%, #0abfef 100%);
  }
  &.variant-2 {
    background: linear-gradient(180deg, #f96230 0%, #f92e33 100%);
    font-family: 'Chonburi', Georgia, 'Times New Roman', Times, serif;
  }
  &.variant-3 {
    background: #fdfdfd;
    color: $black;
    font-family: 'Fraunces', Georgia, 'Times New Roman', Times, serif;
  }
  &.variant-4 {
    background: linear-gradient(180deg, #4cbab5 0%, #347ef1 100%);
    font-family: 'Inter', Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
    font-weight: 600;
  }
  &.variant-5 {
    background: linear-gradient(180deg, #d357a1 0%, #c542e2 100%);
    font-family: 'Gelion', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
      'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 700;
  }
  &.variant-6 {
    background: linear-gradient(
      90deg,
      #ad9efe 0%,
      rgba(173, 158, 254, 0.29) 100%
    );
    font-family: 'Kozuka Mincho Pr6N', Georgia, 'Times New Roman', Times, serif;
    font-weight: 700;
  }
}
