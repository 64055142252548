.carousel {
  padding: 0 40px;
  @media (max-width: 768px) {
    padding: 0;
  }

  &.carousel-slider {
    .control-arrow {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      opacity: 1 !important;
      padding: 26px 30px !important;
      background-color: rgba(255, 255, 255, 0.7) !important;
      margin: auto 0 !important;

      &:hover {
        background-color: rgba(255, 255, 255, 1) !important;
      }

      /* remove default caret icon*/
      &:before {
        content: none !important;
      }

      /* hide arrows when both are disabled */
      &.hidden {
        display: none;
      }
    }

    /* remove default pagination */
    .control-dots {
      display: none;
    }
  }

  .carousel-item {
    // height: 500px;
    // gap: 32px;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // @media (max-width: 768px) {
    //   grid-template-columns: 1fr;
    // }

    display: flex;
    // flex-wrap: wrap;
    gap: 2%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 24px;
    }

    .carousel-card {
      width: 32%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .trending-item {
      width: 32%;
      margin-bottom: 2%;
      cursor: pointer;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .carousel-status {
    display: none;
  }
}

.campaigns-carousel {
  &.purple {
    width: 100vw !important;
    background: $light-purple;
  }
}