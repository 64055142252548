#layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .main-content {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container {
    width: 100%;
    max-width: 1240px;
    padding: 25px 16px;
    margin: auto;

    @media (max-width: 780px) {
      padding: 30px 40px 0 40px;
    }
    @media (max-width: 576px) {
      padding-left: 20px;
      padding-right: 20px;

      /* only applies to sections */
      .main-content > &:not(:first-child) {
        padding-top: 100px;
      }
    }
  }
}
