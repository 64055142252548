.community-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .community {
    --community-item-size: 2rem;
    --community-col-count: 15;
    --community-gap-size: calc(var(--community-item-size) / 3);

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--community-gap-size);
    width: calc(
      (var(--community-item-size) + var(--community-gap-size)) *
        var(--community-col-count) - var(--community-gap-size)
    );

    @media (max-width: 768px) {
      --community-item-size: 40px;
      --community-col-count: 6;
    }

    .community-item {
      img {
        width: var(--community-item-size);
        height: var(--community-item-size);
        border-radius: calc(var(--community-item-size) / 2);
        object-fit: cover;
        cursor: var(--pointer);
      }
    }
  }
}
