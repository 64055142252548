$light-gray: #d9d9d9;
$gray: #b9bdcc;
$dark-gray: #8b8b8b;
$white: #ffffff;
$black: #000000;
$light-black: #1e1e1e;
$dark-blue: #251e41;
$darker-blue: #140d2f;
$background: #0d0628;
$very-light-purple: #d0c6ff;
$light-purple: #8982bd;
$purple: #8600ed;
$dark-purple: #5d0e99;
$pink: #c376ff;
$dark-pink: #824fab;
$dark: #26222a;
$red: #f00;
$dark-red: #800000;
